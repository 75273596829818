const reviewSelfies = [
  {name: "Lew",
   alt: "Straight Teeth Direct Review by Lew",
   url: "pages/reviews/selfies/lew.jpg",
   quote: "If it wasn’t for straight teeth direct, I’d never have gotten my teeth straightened, they make every customer feel like it’s personal and I’m super happy with the results"},
  {name: "CJ",
   alt: "Straight Teeth Direct Review by CJ",
   url: "pages/reviews/selfies/cj.jpg",
   quote: "Amazing results at a fraction of the price. The whole experience was really simple and straight forward."},
  {name: "Dani",
   alt: "Straight Teeth Direct Review by Dani",
   url: "pages/reviews/selfies/dani.jpg",
   quote: "I am very happy with my new smile, the process was very straightforward and seemless. Very few people even noticed that I was wearing aligners"},
  {name: "Wolf",
   alt: "Straight Teeth Direct Review by Wolf",
   url: "pages/reviews/selfies/wolf.jpg",
   quote: "Smiling now more than ever!"},
  {name: "Kate",
   alt: "Straight Teeth Direct Review by Kate",
   url: "pages/reviews/selfies/kate.jpg",
   quote: "I’ve always been really self conscious about my smile and in particular my lateral incisors which would catch on my lip when I’d smile. I’m about to turn 35 and happy to say I can now smile without looking/feeling awkward! The cost of aligners has always been a big barrier for me so to find I could make affordable repayments has been Ideal. Although the process has taken longer than we originally thought, It’s definitely been worth it!"},
  {name: "Victoria",
   alt: "Straight Teeth Direct Review by Victoria",
   url: "pages/reviews/selfies/victoria2.jpg",
   quote: "Really useful that no appointments were necessary"},
  {name: "Esther",
   alt: "Straight Teeth Direct Review by Esther",
   url: "pages/reviews/selfies/esther.jpg",
   quote: "The transformation is impressive 🙂\nIn the past I used to see me in pictures and I really disliked my teeth when smiling, \nAnd now I’m so happy they are all aligned!!\nI’ve recommend Straight Teeth Direct™ to all my friends."},
  {name: "Sarah",
   alt: "Straight Teeth Direct Review by Sarah",
   url: "pages/reviews/selfies/sarah2.jpg",
   quote: "This process was been a welcome surprise. I had been sceptical about the potential to correct my teeth without spending a fortune and I have been very pleasantly surprised. The process was really straightforward and has made a huge difference to my confidence. If you’re self-conscious smiling in photos, you should definitely take a look."},
  {name: "Olivia",
   alt: "Straight Teeth Direct Review by Olivia",
   url: "pages/reviews/selfies/olivia.jpg",
   quote: "I was quite skeptical at first, as it’s all done online and not the traditional way. But the progress has been outstanding. Its really helped my confidence grow."},
  {name: "Alison",
   alt: "Straight Teeth Direct Review by Alison",
   url: "pages/reviews/selfies/alison.jpg",
   quote: "I am surprised how quickly I started to see results and that there really has been no issues with comfort. It’s affordable and a very responsive service."},
  {name: "Dani-Rose O’Ryan",
   alt: "Straight Teeth Direct Review by Dani-Rose O’Ryan",
   url: "pages/reviews/selfies/dani-rose-oryan.jpg",
   quote: "AMAZING company and product. Could not recommend enough. Such an easy process and lovely customer service! I am the happiest and most confident person now"},
  {name: "Nastja",
   alt: "Straight Teeth Direct Review by Nastja",
   url: "pages/reviews/selfies/nastja.jpg",
   quote: "Thank you Straight Teeth Direct for making this transformation possible! I used to always hide my teeth when smiling and the fact that I don’t hide them anymore makes me very happy. I’m surprised how easy the whole process is. Not one person noticed the aligners unless I told them, which is very convenient in my job."},
  {name: "Watchdog341",
   alt: "Straight Teeth Direct Review by Watchdog341",
   url: "pages/reviews/selfies/watchdog341.jpg",
   quote: "I forget how much my teeth have moved over the last year as the movement is very gradual, but looking back, it is quite amazing!"},
  {name: "JJ",
   alt: "Straight Teeth Direct Review by JJ",
   url: "pages/reviews/selfies/jj.jpg",
   quote: "Amazing result with no hassle at all with Straight Teeth Direct, totally 5 stars!"},
  {name: "Lauren",
   alt: "Straight Teeth Direct Review by Lauren",
   url: "pages/reviews/selfies/lauren.jpg",
   quote: "I am extremely happy with my results so far! I am over the moon and cant wait to finish my plan! They are amazing value for money."},
  {name: "Liv",
   alt: "Straight Teeth Direct Review by Liv",
   url: "pages/reviews/selfies/liv.jpg",
   quote: "Such an easy and quick experience and I couldn’t be happier with the results."},
  {name: "Adam",
   alt: "Straight Teeth Direct Review by Adam",
   url: "pages/reviews/selfies/adam.jpg",
   quote: "Great customer service , great communication , great product"},
  {name: "Ben",
   alt: "Straight Teeth Direct Review by Ben",
   url: "pages/reviews/selfies/ben.jpg",
   quote: "I can smile with confidence whereas before I would hide my teeth."},
  {name: "Nicky F.",
   alt: "Straight Teeth Direct Review by Nicky F.",
   url: "pages/reviews/selfies/nicky-f.jpg",
   quote: ""},
  {name: "Lucie",
   alt: "Straight Teeth Direct Review by Lucie",
   url: "pages/reviews/selfies/lucie.jpg",
   quote: "As the weeks went on I got used to having the aligners in my mouth and the eating/cleaning routine. The customer service has been amazing too. Would definitely recommend straight teeth direct!"},
  {name: "Magi",
   alt: "Straight Teeth Direct Review by Magi",
   url: "pages/reviews/selfies/magi.jpg",
   quote: "I want to say big thank you to all people from Straight teeth Direct who helped me for my case . Now I am so happy with my new smile and I feel free to give smile to all people around me without worrying about how my teeth look like. Now i feel more confident than ever. Thank you Straight Teeth Direct I love my new smile!!!"},
  {name: "Linda",
   alt: "Straight Teeth Direct Review by Linda",
   url: "pages/reviews/selfies/linda.jpg",
   quote: "I realised my teeth were never going to stop bothering me…..so I did it….now I love my smile"},
  {name: "Elizabeth",
   alt: "Straight Teeth Direct Review by Elizabeth",
   url: "pages/reviews/selfies/elizabeth.jpg",
   quote: "Every step of the way with Straight teeth direct has been easy and convenient and I am absolutely thrilled and so happy with the results. I have got my confidence back and smile in all my photos now."},
  {name: "Joana",
   alt: "Straight Teeth Direct Review by Joana",
   url: "pages/reviews/selfies/joana.jpg",
   quote: "It was the best thing I could ever do to myself ! So happy that I’ve done it thank you again for everything 🙌🏻💗"},
  {name: "Laura",
   alt: "Straight Teeth Direct Review by Laura",
   url: "pages/reviews/selfies/laura.jpg",
   quote: "Can you believe I’m wearing braces here? So glad I chose @straightteethdirect"},
  {name: "H",
   alt: "Straight Teeth Direct Review by H",
   url: "pages/reviews/selfies/h.jpg",
   quote: "“Hi everyone!\nThough I was a bit hesitant at first, I decided to go for my treatment plan. The results are impressive and the price is great for the service provided. If you’re thinking of getting this treatment, don’t hesitate – achieve your beautiful smile!”"},
  {name: "Alice",
   alt: "Straight Teeth Direct Review by Alice",
   url: "pages/reviews/selfies/alice.jpg",
   quote: "I used to have wire braces as a child which were painful but they don’t tell you your teeth move back, straight teeth direct have put me back to my perfect smile! I actually enjoy wearing them and no one ever realises I have them in!"},
  {name: "Max",
   alt: "Straight Teeth Direct Review by Max",
   url: "pages/reviews/selfies/max.jpg",
   quote: "What I like best, in my case, is the flexibility and fast communication via web which fits a busy travel lifestyle. Going to a local dentist to do this sounds inefficient after this treatment."},
  {name: "Kayleigh",
   alt: "Straight Teeth Direct Review by Kayleigh",
   url: "pages/reviews/selfies/kayleigh.jpg",
   quote: "Finally here! The route to straighter teeth has started, 1st set on and ready to go, #mysmiledelivered"},
  {name: "Martyn",
   alt: "Straight Teeth Direct Review by Martyn",
   url: "pages/reviews/selfies/martyn.jpg",
   quote: "Absolutely over the moon with my straight teeth direct results, would definitely recommend them to anyone that wants there teeth straight at a fraction of the price other companies charge!! Thanks Straight Teeth Direct!"},
  {name: "Anna",
   alt: "Straight Teeth Direct Review by Anna",
   url: "pages/reviews/selfies/anna.jpg",
   quote: "I’m happy to be one of pioneers who took a risk and just entrusted myself to Straight Teeth Direct. By the way, if you have any problems with your spine, neck or permanently pinched nerves start with correction of your bite. As all our bones are tightly interconnected, even seeming insignificant flaw may lead to dysfunction of skeleton. Straight Teeth Direct is really able to come your dreams about beautiful and healthy smile true:) I sincerely appreciate the company’s assistance, brilliant service and treatment outcomes!"},
  {name: "Mirea",
   alt: "Straight Teeth Direct Review by Mirea",
   url: "pages/reviews/selfies/mirea.jpg",
   quote: "It was the best decision i have ever made. Amazing customer service and an amazing company overall big well done."},
  {name: "John",
   alt: "Straight Teeth Direct Review by John",
   url: "pages/reviews/selfies/john.jpg",
   quote: "I’m so happy with me progress so far and cannot wait to see my final result. The whole process has been easy and convenient, with the team always providing updates and feedback. Straight Teeth Direct really are providing a produce and service far beyond the price being charged. I highly recommend this to anyone looking for a straighter smile"},
  {name: "Nat B",
   alt: "Straight Teeth Direct Review by Nat B",
   url: "pages/reviews/selfies/nat-b.jpg",
   quote: "I honestly can’t believe the transformation. After being told by orthodontists it would cost thousands of dollars and I would probably need gum surgery, I spent a fraction of that and now have the perfect smile and my gums are so healthy, because I take even more care of them! I’m a Brit in Canada with a North American smile now! Even the Canadians are asking where I got my aligners from they are so impressed."},
  {name: "Dmytro",
   alt: "Straight Teeth Direct Review by Dmytro",
   url: "pages/reviews/selfies/dmytro.jpg",
   quote: "It was a great experience, the process was nice and easy, especially after 1-2 months when you get used to it. I would definitely recommend it."},
  {name: "Gemma",
   alt: "Straight Teeth Direct Review by Gemma",
   url: "pages/reviews/selfies/gemma.jpg",
   quote: "Until I found @straightteethdirect. These guys are amazing! The cost is fixed with no hidden fees. Their customer service is outstanding, the aligners are of a high quality and the app makes the process so much easier. I honestly cannot fault them at all. It’s wonderful to know that by Christmas, my smile will be transformed! Thank you @straightteethdirect!"},
  {name: "Gary",
   alt: "Straight Teeth Direct Review by Gary",
   url: "pages/reviews/selfies/gary.jpg",
   quote: "The result I have had have been better than I expected and the customer service has been excellent, anytime I have had any questions someone has always replied straight away. Would definitely recommend."},
  {name: "AB",
   alt: "Straight Teeth Direct Review by AB",
   url: "pages/reviews/selfies/ab.jpg",
   quote: "Pretty happy with the results. Never had a problem with communication. This thing works 🙂"},
  {name: "Jo Bray",
   alt: "Straight Teeth Direct Review by Jo Bray",
   url: "pages/reviews/selfies/jo-bray.jpg",
   quote: "After 30+ years of hiding my mouth when I smile and avoiding photos, I can finally smile confidently and the whole experience has been life changing."},
  {name: "Emily",
   alt: "Straight Teeth Direct Review by Emily",
   url: "pages/reviews/selfies/emily.jpg",
   quote: "My smile is so much bigger now physically, I have a more teethy smile, but I also smile more now as I have a better looking smile. Using Straight Teeth Direct has been so easy and straight forward. I would always recommend them"},
  {name: "Leah",
   alt: "Straight Teeth Direct Review by Leah",
   url: "pages/reviews/selfies/leah.jpg",
   quote: "I was amazed how quickly I started to see the results, so seeing this is great!"},
  {name: "Laura Holmes",
   alt: "Straight Teeth Direct Review by Laura Holmes",
   url: "pages/reviews/selfies/laura-holmes.jpg",
   quote: "I woke up today feeling extremely under the weather then this arrived and it’s cheered me up! The start of something new 😁#mysmiledelivered"},
  {name: "Juli",
   alt: "Straight Teeth Direct Review by Juli",
   url: "pages/reviews/selfies/juli.jpg",
   quote: "I didn’t know how much I needed it until I was through"},
  {name: "Sue",
   alt: "Straight Teeth Direct Review by Sue",
   url: "pages/reviews/selfies/sue.jpeg",
   quote: "My first set of aligners on 😎: let’s start the journey to a new smile!"},
  {name: "Tiffany",
   alt: "Straight Teeth Direct Review by Tiffany",
   url: "pages/reviews/selfies/tiffany.jpg",
   quote: "I always felt like people were looking at my teeth when I was talking to them, I have to meet candidates and clients daily and I tried to hide my teeth behind my gums, but now I have so much confidence and I don’t even care if people notice my aligners, in fact I love it when people ask me about it. I honestly smile more and I am happier. I can honestly say I’ve never experienced such good customer service, there is always someone there to help and there is never a question too big or too small, the team genuinely cares about your teeth journey and are there as little or as much as you need them! Thank you 🙂"},
  {name: "Jessica",
   alt: "Straight Teeth Direct Review by Jessica",
   url: "pages/reviews/selfies/jessica.jpg",
   quote: "I love the before/after! It’s incredible how much my teeth have changed, I can’t even believe how bad the crowding used to be!"},
  {name: "Lydia",
   alt: "Straight Teeth Direct Review by Lydia",
   url: "pages/reviews/selfies/lydia.jpg",
   quote: "Seeing myself in pictures now, I notice for the first time, that I’m actually smiling with my teeth showing! I can’t believe how much can be achieved dentally without actually visiting a specialist. I was told that I would have to have teeth removed to straighten them, I decided that I’d have a review with Straight Teeth Direct and now I just regret that it took me so long to sign up! The process was painful at times, I have a slight lisp, but the difference makes everything worth it! Thank you!"},
  {name: "Awa",
   alt: "Straight Teeth Direct Review by Awa",
   url: "pages/reviews/selfies/awa.jpg",
   quote: "Thank you again for everything."},
  {name: "Natasha R.",
   alt: "Straight Teeth Direct Review by Natasha R.",
   url: "pages/reviews/selfies/natasha-r.jpg",
   quote: ""},
  {name: "Jazzmine",
   alt: "Straight Teeth Direct Review by Jazzmine",
   url: "pages/reviews/selfies/jazzmine.jpg",
   quote: "I really did not expect these results! I‘m totally happy and would recommend straight teeth direct to everyone who is still thinking about doing this! Thank you guys!"},
  {name: "Stella",
   alt: "Straight Teeth Direct Review by Stella",
   url: "pages/reviews/selfies/stella.jpg",
   quote: "The staff were so lovely and helpful which made the whole process easy and enjoyable. Really happy with the results so far and would recommend Straight teeth direct to anyone considering getting braces. 😊"},
  {name: "Katey Martin",
   alt: "Straight Teeth Direct Review by Katey Martin",
   url: "pages/reviews/selfies/katey-martin.jpeg",
   quote: "Happy birthday to me. This little box is going to make me look like her #MySmileDelivered"},
  {name: "Catherine Kiev",
   alt: "Straight Teeth Direct Review by Catherine Kiev",
   url: "pages/reviews/selfies/catherine-kiev.jpg",
   quote: "Your company has changed my life, I’m so glad i took a chance on an Instagram ad and checked out your site!\nI am actually FLOORED at the kind of progress made so far. I’m so happy with my results, no more pain or discomfort, It’s incredible. I’m eternally grateful to have found your company and all the support I have received online has been great!\nI can’t wait for my new and improved smile!"},
  {name: "Flora",
   alt: "Straight Teeth Direct Review by Flora",
   url: "pages/reviews/selfies/flora.jpg",
   quote: "One thing that really surprised me is that I felt no pain at all during the process! I’ve had braces when I was younger and it was awful, so I was expecting to hurt again 😄"},
  {name: "Sian Williams",
   alt: "Straight Teeth Direct Review by Sian Williams",
   url: "pages/reviews/selfies/sian-williams.jpg",
   quote: "Just want to say thank you soooooo much for what st direct have done..you have all been amazing..i have 2 aligners left to go! my progress is awesome 😁\nYour smile is with you for a life!\nStraight teeth direct has changed my life 😁"},
  {name: "Katie",
   alt: "Straight Teeth Direct Review by Katie",
   url: "pages/reviews/selfies/katie.jpg",
   quote: "It was great to straighten my teeth pretty quickly and so discretely – no one ever noticed I was wearing aligners!"},
  {name: "JC",
   alt: "Straight Teeth Direct Review by JC",
   url: "pages/reviews/selfies/jc.jpg",
   quote: "After lots of searching I am very happy I chose StraightTeethDirect! Whenever I have had any concerns or doubts, they have been very quick to reply and reassure me that I am on track. Almost done and thrilled with the results so far!"},
  {name: "Mimi",
   alt: "Straight Teeth Direct Review by Mimi",
   url: "pages/reviews/selfies/mimi.jpg",
   quote: "I am tremendously happy with my results and have been very impressed by how timely the whole treatment has been. A new smile in about nine months!"},
  {name: "Adama Manga",
   alt: "Straight Teeth Direct Review by Adama Manga",
   url: "pages/reviews/selfies/adama-manga.jpg",
   quote: "Really good way to get the smile you want, without all the hassle of going to the dentist. Communication was good, I received a message about the progress of my smile often and advice on how to help with the pain….. yes it did hurt sometime but at the end of the day your teeth are being moved into place. I couldn’t be happier with the result of my treatment would very highly recommend."},
  {name: "Alex",
   alt: "Straight Teeth Direct Review by Alex",
   url: "pages/reviews/selfies/alex.jpg",
   quote: "Wow so happy with my teeth now and how easy the whole process has been with straightteethdirect"},
  {name: "Charlotte",
   alt: "Straight Teeth Direct Review by Charlotte",
   url: "pages/reviews/selfies/charlotte.jpg",
   quote: "I never expected my teeth to move this much in such a short time and to see such a huge difference already is amazing! Definitely recommend this to anyone"},
  {name: "Toni Bloomfield",
   alt: "Straight Teeth Direct Review by Toni Bloomfield",
   url: "pages/reviews/selfies/toni-bloomfield.jpg",
   quote: "Cannot wait to receive my personalised plan back 😍 super easy to make impressions! Everything was there with detailed instructions!!! LOVING my experience so far 😍😍"},
  {name: "Laura",
   alt: "Straight Teeth Direct Review by Laura",
   url: "pages/reviews/selfies/laura.jpg",
   quote: "Can you believe I’m wearing braces here? So glad I chose @straightteethdirect"},
  {name: "Becky G",
   alt: "Straight Teeth Direct Review by Becky G",
   url: "pages/reviews/selfies/becky-g.jpg",
   quote: "I never thought that my teeth would ever be straight without having to go through the conventional route of metal braces. The whole process of using the aligners from Straight Teeth Direct has been smooth and easy and I am so happy with the results so far! I would definitely recommend anyone to use this for themsleves!"},
  {name: "Mary M",
   alt: "Straight Teeth Direct Review by Mary M",
   url: "pages/reviews/selfies/mary-m.jpg",
   quote: "Choosing Straight Teeth Direct is by far the best decision I have ever made! I never thought I would ever like my teeth and now after only 8 months I absolutely love them and get compliments on them all the time The whole process from start to finish has been so easy and the staff are always on hand to answer any Qs or concerns I may have. I have recommended Straight Teeth Direct to all my family & friends"},
  {name: "Vivek Verma -India",
   alt: "Straight Teeth Direct Review by Vivek Verma -India",
   url: "pages/reviews/selfies/vivek-verma--india.jpeg",
   quote: "It is actually amazing to smile with confidence. I have spent so much time to analyse the options but I am happy that finally I choose the best. I didn’t even expect that my progress will be even faster than the projected one. So go for a better smile 😀"},
  {name: "Lizzie",
   alt: "Straight Teeth Direct Review by Lizzie",
   url: "pages/reviews/selfies/lizzie.jpg",
   quote: "I wish I’d done this year’s ago. It’s given me a huge confidence boost which has has had a positive effect on all aspects of my life. I find myself smiling so much more than I used to."},
  {name: "Daisy Sheehan",
   alt: "Straight Teeth Direct Review by Daisy Sheehan",
   url: "pages/reviews/selfies/daisy-sheehan.jpg",
   quote: "Hopefully this will be a teethey smile soon 😀"},
  {name: "Vicky Lou",
   alt: "Straight Teeth Direct Review by Vicky Lou",
   url: "pages/reviews/selfies/vicky-lou.jpg",
   quote: "I can honestly say, I’m so pleased with Straight Teeth Direct. I was doubtful at first as everything is done online using their app but the whole journey has been fantastic and the customer service is excellent. What I was surprised at is that I didn’t need to have attachments which was great as I’m 46 and didn’t want anyone to know I was having my teeth straightened. My teeth are lovely and straight and I would definitely recommend."},
  {name: "Katie McCrann",
   alt: "Straight Teeth Direct Review by Katie McCrann",
   url: "pages/reviews/selfies/katie-mccrann.jpg",
   quote: "Let’s get this party started!"},
  {name: "Anonymous",
   alt: "Straight Teeth Direct Review by Anonymous",
   url: "pages/reviews/selfies/anonymous.jpg",
   quote: "I found the process convenient"},
  {name: "Maisie",
   alt: "Straight Teeth Direct Review by Maisie",
   url: "pages/reviews/selfies/maisie.jpg",
   quote: "I’m so thrilled I gave myself the gift of a more attractive and confident smile!"},
  {name: "Sarah",
   alt: "Straight Teeth Direct Review by Sarah",
   url: "pages/reviews/selfies/sarah.jpg",
   quote: "This process was been a welcome surprise. I had been sceptical about the potential to correct my teeth without spending a fortune and I have been very pleasantly surprised. The process was really straightforward and has made a huge difference to my confidence. If you’re self-conscious smiling in photos, you should definitely take a look."},
  {name: "Pierre",
   alt: "Straight Teeth Direct Review by Pierre",
   url: "pages/reviews/selfies/pierre.jpg",
   quote: "I’m honestly so happy I finally found an easy and affordable way to get my teeth straightened. It’s comfortable and actually truly invisible, people still don’t know I’m wearing aligners. I’m really grateful to Straight Teeth Direct, thank you for being reactive. I LOVE my new smile !"},
  {name: "Victoria",
   alt: "Straight Teeth Direct Review by Victoria",
   url: "pages/reviews/selfies/victoria.jpg",
   quote: "Really useful that no appointments were necessary"},
  {name: "Vanessa",
   alt: "Straight Teeth Direct Review by Vanessa",
   url: "pages/reviews/selfies/vanessa.jpg",
   quote: "Here is my picture with my aligners on… You can’t see them their invisible… In 8 months my smile will completely change, and I’ll be able to for the first time smile with confidence and not hide my smile norbe ashamed or shy of my smile. This is definitely the best thing and feeling ever!\nThank you so much to the entire straight teeth direct team!!! Couldn’t have taken this huge step without all your expertise, tips, help and amazing service. 👏👏👏👏👏. Can’t wait to see and compare in 8 months to see and feel the difference. Thanks a million. XO – Vanessa"},
  {name: "Julie V.",
   alt: "Straight Teeth Direct Review by Julie V.",
   url: "pages/reviews/selfies/julie-v.jpg",
   quote: ""},
  {name: "Marta Lentner",
   alt: "Straight Teeth Direct Review by Marta Lentner",
   url: "pages/reviews/selfies/marta-lentner.jpg",
   quote: "Can’t even express how excited I am for that bit of pain . They are finally here my clear aligners . In 10 months that picture will look completely different for me as my teeth will be straight and beautiful. So excited for it  Thank you @straightteethdirect i have them on in that picture and they fit perfectly and pain is bearable nothing to be worried about seriously. Let’s wait for results now"},
  {name: "Lauren Ianson",
   alt: "Straight Teeth Direct Review by Lauren Ianson",
   url: "pages/reviews/selfies/lauren-ianson2.jpg",
   quote: ""},
  {name: "Lauren H.",
   alt: "Straight Teeth Direct Review by Lauren H.",
   url: "pages/reviews/selfies/lauren-h.jpg",
   quote: ""},
  {name: "Christina Norton",
   alt: "Straight Teeth Direct Review by Christina Norton",
   url: "pages/reviews/selfies/christina-norton.jpeg",
   quote: "Impressions kit arrived ! Only took 3 days from dispatch ️ Impressed already"},
  {name: "Joanna",
   alt: "Straight Teeth Direct Review by Joanna",
   url: "pages/reviews/selfies/joanna.jpg",
   quote: "“After 6 months wearing my aligners I notice a huge improvement, my teeth are almost straight. Still few weeks to go and I will have a perfect smile for my summer vacations. Thank you Straight Teeth Direct :)”"},
  {name: "Eugenia",
   alt: "Straight Teeth Direct Review by Eugenia",
   url: "pages/reviews/selfies/eugenia.jpg",
   quote: "At first I was not very convinced to start the process of aligning my teeth remotely and even less from another country and speaking a different language, but after researching and seeing all the professionals who were behind Straight Teeth Direct, I made the decision, and it was the best decision! I have to say that despite the price, they do not skimp on quality, and despite the distance, the customer service is unbeatable! So I recommend to anyone who is thinking about it… don’t hesitate on take the step because you will not regret!! 😀"},
  {name: "Alexina Brown",
   alt: "Straight Teeth Direct Review by Alexina Brown",
   url: "pages/reviews/selfies/alexina-brown.jpg",
   quote: "I am so pleased with my results so far and I can’t wait to finish and use my whitening kit for my final results!"},
  {name: "Adil",
   alt: "Straight Teeth Direct Review by Adil",
   url: "pages/reviews/selfies/adil.jpg",
   quote: "Enjoying the smile made possible by Straight Teeth Direct"},
  {name: "Tiffany Thornton",
   alt: "Straight Teeth Direct Review by Tiffany Thornton",
   url: "pages/reviews/selfies/tiffany-thornton.jpeg",
   quote: "Braces?! What braces?! 😉 #MySmileDelivered"},
  {name: "Helin Wildner",
   alt: "Straight Teeth Direct Review by Helin Wildner",
   url: "pages/reviews/selfies/helin-wildner.jpg",
   quote: "Let’s do this! #mysmiledelivered"},
  {name: "Sarihanna",
   alt: "Straight Teeth Direct Review by Sarihanna",
   url: "pages/reviews/selfies/sarihanna.jpg",
   quote: "I’m more than excited to have my Impression Box delivered already… Shipped so fast to Greece along with an amazing immediate support and response from the #StraightTeethDirect team. Cant wait to start the “journey” of a beautiful smile thanks to you guys"},
  {name: "Elina",
   alt: "Straight Teeth Direct Review by Elina",
   url: "pages/reviews/selfies/elina.jpg",
   quote: "I never knew how easy it would be to get straight teeth! My progress so far has been wonderful and I smile now all the time. Thanks to Straight Teeth Direct! 🙂"},
  {name: "Katie",
   alt: "Straight Teeth Direct Review by Katie",
   url: "pages/reviews/selfies/katie.jpeg",
   quote: "It was great to straighten my teeth pretty quickly and so discretely – no one ever noticed I was wearing aligners!"},
  {name: "Hayley",
   alt: "Straight Teeth Direct Review by Hayley",
   url: "pages/reviews/selfies/hayley.jpg",
   quote: "I am so glad I found straight teeth direct. They’ve kept me up to date on my progression throughout my treatment, checking in every so often to see how I’m getting along and to see if I have any questions. I’m just over half way through my treatment (5 months in) and have already noticed a massive change in my teeth. I am now confident with my smile and can’t wait to see the end result. A massive thank you to straight teeth direct!"},
  {name: "Flora",
   alt: "Straight Teeth Direct Review by Flora",
   url: "pages/reviews/selfies/flora.jpg",
   quote: "One thing that really surprised me is that I felt no pain at all during the process! I’ve had braces when I was younger and it was awful, so I was expecting to hurt again 😄"},
  {name: "Paige Waite",
   alt: "Straight Teeth Direct Review by Paige Waite",
   url: "pages/reviews/selfies/paige-waite.jpeg",
   quote: "On a journey to more confidence, more laughter and more smiles! #mysmiledelivered"},
  {name: "Sharon Vd",
   alt: "Straight Teeth Direct Review by Sharon Vd",
   url: "pages/reviews/selfies/sharon-vd.jpg",
   quote: "Yesterday when I was picking my bag up from the airport I got a compliment from this old lady, and she said “nice teeth”; I never got a compliment about my teeth before, just about my smile because it’s gorgeous, so thank you!"},
  {name: "Marie M",
   alt: "Straight Teeth Direct Review by Marie M",
   url: "pages/reviews/selfies/marie-m.jpg",
   quote: "Ravie de recevoir mes dernières gouttières, et en plus livraison au boulot, merci Straight Teeth Direct !!!\n#MySmileDelivered"},
  {name: "Dearbhla",
   alt: "Straight Teeth Direct Review by Dearbhla",
   url: "pages/reviews/selfies/dearbhla.jpg",
   quote: "New Year – New Teeth\nI honestly can’t get over the results!"},
  {name: "Andreia",
   alt: "Straight Teeth Direct Review by Andreia",
   url: "pages/reviews/selfies/andreia.jpg",
   quote: "I received my aligners in my StraightBox and I have started my #SmileJourney! I’m very excited and happy!!! In 9 months I will share the difference in my smile 😁 #mysmiledelivered"},
  {name: "Jess Hornsey",
   alt: "Straight Teeth Direct Review by Jess Hornsey",
   url: "pages/reviews/selfies/jess-hornsey.jpg",
   quote: "Time to get a 3D model of my teeth #mysmiledelivered #putty #3dmodel #aligners"},
  {name: "Debbie",
   alt: "Straight Teeth Direct Review by Debbie",
   url: "pages/reviews/selfies/debbie.jpg",
   quote: "My 5 month progress. Thanks so much I am so please"},
  {name: "Mauranne Lefebvre",
   alt: "Straight Teeth Direct Review by Mauranne Lefebvre",
   url: "pages/reviews/selfies/mauranne-lefebvre.jpg",
   quote: "Got my smile delivered !"},
  {name: "Andreia Pinho",
   alt: "Straight Teeth Direct Review by Andreia Pinho",
   url: "pages/reviews/selfies/andreia-pinho.jpg",
   quote: "I decided it was time to improve my smile.😀\nI chose a fun, fast and easy way to do it: @Straightteethdirect. #MySmileDelivered"},
  {name: "Aalok",
   alt: "Straight Teeth Direct Review by Aalok",
   url: "pages/reviews/selfies/aalok.jpg",
   quote: "Our co-founder rocking his invisible aligners in the sun 🙂\nHere you can see how top aligners look VS no aligners on the bottom teeth, not so obvious right?! #MySmileDelivered"},
  {name: "Lauren Ianson",
   alt: "Straight Teeth Direct Review by Lauren Ianson",
   url: "pages/reviews/selfies/lauren-ianson.jpg",
   quote: ""},
  {name: "Eugenia H",
   alt: "Straight Teeth Direct Review by Eugenia H",
   url: "pages/reviews/selfies/eugenia-h.jpg",
   quote: "#mysmiledelivered"},
  {name: "Maria",
   alt: "Straight Teeth Direct Review by Maria",
   url: "pages/reviews/selfies/maria.jpg",
   quote: "SO excited about starting #MySmileDelivery journey😊"},
  {name: "Katie Davis",
   alt: "Straight Teeth Direct Review by Katie Davis",
   url: "pages/reviews/selfies/katie-davis.jpg",
   quote: "First set of aligners in!"},
  {name: "Dearbhlá Barrett",
   alt: "Straight Teeth Direct Review by Dearbhlá Barrett",
   url: "pages/reviews/selfies/dearbhla-barrett.jpg",
   quote: "Such an easy and straight forward process. All the team at StraighTteethDirect have been so helpful and on hand to answer any queries or questions I’ve had. The aligners are so surprisingly comfortable and invisible that most of the time I forget I have them on. So excited to continue on my new smile journey. Highly recommend StraightTeethDirect to anyone who is unhappy with their teeth but don’t want the hassle and costs of going through the orthodontist. 😁"},
  {name: "Harriette Olivia",
   alt: "Straight Teeth Direct Review by Harriette Olivia",
   url: "pages/reviews/selfies/harriette-olivia.jpg",
   quote: "My mould kit came in beautiful packaging, that I know I will for sure make use of when I’m finished. I have always wanted braces, because my teeth are just that little bit crooked. I don’t tend to smile, because I just don’t like the look of my smile. I’m hoping that when I start treatment I will really begin to love my smile. You won’t be able to stop me. Hopefully it will give me a new lease of confidence. #MySmileDelivered"},
  {name: "Thuy Ho",
   alt: "Straight Teeth Direct Review by Thuy Ho",
   url: "pages/reviews/selfies/thuy-ho.jpg",
   quote: "Finallyyyy, my SmileBox has arrived safe and sound 😍 Couldn’t get it when I was in Vietnam, but now as I’m in Europe, I can get it with ease, and start perfecting my precious teeth 😁 I’m so excited to try this out and straighten my lower set without wearing painful and expensive brace ♥ Isn’t it great that now you needn’t spend tons of money to have a pretty smile 😉?! #MySmileDelivered #SmileBox"},
  {name: "Anja Psag",
   alt: "Straight Teeth Direct Review by Anja Psag",
   url: "pages/reviews/selfies/anja-psag.jpg",
   quote: "Got my SmileBox and ready to make my own moulds to get aligners – AT HOME! Woohoo This is so cool. Great way to get straight teeth without the need for trips to the orthodontist! Thank you #straightteethdirect team 💋💕\n#mysmiledelivered"},
  {name: "Jane Nkiko",
   alt: "Straight Teeth Direct Review by Jane Nkiko",
   url: "pages/reviews/selfies/jane-nkiko.jpg",
   quote: "Received my beautiful SmileBox, I am thrilled to get started! Watch my impression demo video here: https://goo.gl/R5aKNK\n#MySmileDelivered"},
  {name: "Tonia Salt",
   alt: "Straight Teeth Direct Review by Tonia Salt",
   url: "pages/reviews/selfies/tonia-salt.jpg",
   quote: "I’m obsessed with my @straightteethdirect kit!! Such a good idea to be able to do your own impressions at home, without dreading going to the orthodontist. Doing my own moulds was so so easy & hilarious – cannot wait!! #MySmileDelivered"},
  {name: "Naomi",
   alt: "Straight Teeth Direct Review by Naomi",
   url: "pages/reviews/selfies/naomi.jpg",
   quote: "So excited to receive my SmileBox! Honestly can’t believe how quick and simple this process is…even for me 😉 I can’t wait to explore getting my teeth back to perfection after getting my braces off four years ago!"},
  {name: "Sarah Lonergan",
   alt: "Straight Teeth Direct Review by Sarah Lonergan",
   url: "pages/reviews/selfies/sarah-lonergan.jpg",
   quote: "Started my journey : and I have to say these guys are amazing any questions were answered straight away and super easy to do your impressions and the whole process has been great : delighted 💕😃 #MySmileDelivered"},
  {name: "Rebecca Larsen",
   alt: "Straight Teeth Direct Review by Rebecca Larsen",
   url: "pages/reviews/selfies/rebecca-larsen.jpg",
   quote: "Braces baby 🤓\n#clearaligners #braces #mysmiledelivered\nI’m in the process of ordering my StraightBox. The course of this whole process has been easy, pleasant and basically hassle free."},
  {name: "the_sookie",
   alt: "Straight Teeth Direct Review by the_sookie",
   url: "pages/reviews/selfies/the_sookie.jpg",
   quote: "My SmileBox™ is here! 😄Thank you so much StraightTeethDirect! I can’t wait to see what my perfect smile could look like 😁\n#MySmileDelivered"},
  {name: "Sara Buzleta",
   alt: "Straight Teeth Direct Review by Sara Buzleta",
   url: "pages/reviews/selfies/sara-buzleta.jpg",
   quote: "So much fun, so easy, so quick! Simply in love with #MySmileDelivered & Straightteethdirect team! 💖"},
  {name: "Χριστίνα - Ευτέρπη Φάρκωνα",
   alt: "Straight Teeth Direct Review by Χριστίνα - Ευτέρπη Φάρκωνα",
   url: "pages/reviews/selfies/xristina---euterpi-pharkona.jpg",
   quote: "Simple and professional, the perfect way to get my dream smile! #MySmileDelivered"},
  {name: "Marija Priselac",
   alt: "Straight Teeth Direct Review by Marija Priselac",
   url: "pages/reviews/selfies/marija-priselac.jpg",
   quote: "So easy, fast and effortless! Such a great experience with StraightTeethDirect team. Thank you so much!"},
  {name: "Faten MK",
   alt: "Straight Teeth Direct Review by Faten MK",
   url: "pages/reviews/selfies/faten-mk.jpg",
   quote: "So happy to be able to use the Straight Teeth Direct™ kit to finally perfect my smile! Thank you @straightteethdirect 😃💋 #MySmileDelivered"},
  {name: "Estefania Ramos",
   alt: "Straight Teeth Direct Review by Estefania Ramos",
   url: "pages/reviews/selfies/estefania-ramos.jpg",
   quote: "#Smile #MySmileBox\n#MySmileDelivered"}];

export default reviewSelfies;
