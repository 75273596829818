import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { Cover, FAQs, GridResults, IconsAlignerService, Layout, Link, SectionNumbered, SEO } from 'components';
import { routes } from 'utils/routes';
import { guaranteesFaqsData } from 'utils/config/faqs';
import reviewSelfies from 'utils/config/reviewSelfies';

import content from './guarantee.yml';

const GuaranteesPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} className='guarantee-wrapper'>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Link to={routes.apart.path} {...routes.apart} className='cta-sidetab hide-mobile'>What sets us apart?</Link>

    <Cover
      title='We promise to<br/>make you smile'
      text='In a day and age where quality online products are few and far between, we want to set your mind at ease. So, here’s our promise: Get results with no hidden costs – simple!'
      button={{ label: 'Get started', ...routes.guarantees }}
      image={{ url: 'pages/ourpromise_cover.jpg', alt: 'Straight teeth guarantees to make you smile' }}
      overlap
      stamp
      trustpilot
    />

    <IconsAlignerService />

    <div className='section-numbered-wrapper'>
      <SectionNumbered
        title='Suitability &<br/>Impressions<br/>Guarantee'
        list={[<Fragment>Be 100% sure this is going to work for you after your case has been approved by a dentist through the free e-consultation process</Fragment>, <Fragment>You won’t have to pay more even if more impressions are needed [other companies typically charge half of the cost again]</Fragment>]}
        image={{ url: 'pages/guarantee_one.jpg', alt: 'Suitability and Impressions Guarantee' }}
        iconNumber={{ url: 'icons/icon_one.svg', alt: 'Ribbon for number one' }}
        className='number-one'
      />

      <SectionNumbered
        title='Lost & Broken Aligner Replacement<br/>Guarantee'
        list={[<Fragment>Accidents happen! That’s why you won’t have to pay extra in case you break or lose an aligner during treatment [other companies typically charge <T id="guarantees.replacement">{txt => txt}</T> per replacement aligner]</Fragment>]}
        image={{ url: 'pages/guarantee_two.jpg', alt: 'Lost and Broken Aligner Guarantee' }}
        iconNumber={{ url: 'icons/icon_two.svg', alt: 'Ribbon for number two' }}
        reverse
      />

      <SectionNumbered
        title='Results Agreement Guarantee'
        list={[<Fragment>Your smile simulation and full report show you your personal, clinically attainable results before even starting treatment. That way, you’ll know exactly where your teeth straightening journey will take you before committing. Get the results agreed in your plan with no hidden costs!</Fragment>, <Fragment>Receive all aligners needed to achieve the goals set out in your plan, which shows the optimum possible outcome based on your unique dental structure [other companies typically charge a minimum of <T id="guarantees.refinements">{txt => txt}</T> for refinements]</Fragment>]}
        image={{ url: 'pages/guarantee_three.jpg', alt: 'Results Agreement Guarantee' }}
        iconNumber={{ url: 'icons/icon_three.svg', alt: 'Ribbon for number three' }}
      />

      <SectionNumbered
        title='Extended Guarantee After Treatment'
        list={[<Fragment>Join the Maintainer Plan to extend your guarantee after treatment and keep straight teeth for life</Fragment>, <Fragment>Receive new retainers every 6 months for only <T id="guarantees.retainers">{txt => txt}</T>/year [clinics typically charge <T id="guarantees.retainersClinics">{txt => txt}</T> per set of retainers]</Fragment>]}
        image={{ url: 'pages/guarantee_four.jpg', alt: 'Extended Guarantee' }}
        iconNumber={{ url: 'icons/icon_four.svg', alt: 'Ribbon for number four' }}
        reverse
      />
    </div>

    <FAQs title='View the Terms & Conditions' faqsData={guaranteesFaqsData} className='mb-50 mt-90' />

    <h3 className='section-title mb-50 mt-90'>#MySmileDelivered reviews</h3>

    <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction className='mb-90' />
  </Layout>
);

GuaranteesPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default GuaranteesPage;
